import React from 'react';
import { FourOhFour } from 'gatsby-theme-carbon';

const links = [
    { href: '/getting-started', text: 'Getting started with KOPE' },
    { href: '/kope', text: 'KOPE User Guide' },
]

const Custom404 = () => <FourOhFour links={links} />;

export default Custom404;
